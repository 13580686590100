.output3 {
	.scenario_title {
		text-transform: none;
		font-size: 18px;

		span {
			font-weight: 400;
		}
	}

	&__variables {
		.scenario1,
		.scenario2 {
			margin-bottom: 50px;

			table {
				thead {
					th {
						width: auto;
						font-weight: 700;

						&.right {
							text-align: right;
						}
					}
				}

				tbody {
					td {
						width: auto;

						&.name {
							width: 35%;
						}

						&.cell {
							text-align: right;
							padding-right: 5px;
						}

						&.hidden {
							opacity: 0;
							pointer-events: none;
						}
					}

					tr {
						&.totals {
							font-weight: 700 !important;
						}
					}
				}
			}
		}
	}
}