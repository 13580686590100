@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

$primarycolor: #026aa3;
$secondarycolor: #CAE9F8;
$white: #fff;
$black: #000;

$red: #e95f63;
$blue: #008ABB;
$blue-dark: #00546c;
$blue-light: #009BDD;
$blue-bg: #DFF2FB;

$green: #56ae53;
$green-dark: #3f863f;
$green-light: #5ebe5e;
$green-bg: #f1ffef;

$blueish: #99D4D8;
$greenish: #9aa539;

$yellow-bg: #FBF19C;;
$yellow: #FAE96B;
$yellow-dark: #F9E252;

$text-color: #232323;
$link-color: $blue-light;

$titlefont: 'Aller',  sans-serif;
$bodyfont: 'Aller', sans-serif;

