html {
	box-sizing: border-box;
	background-color: $secondarycolor;
	height: 100%;
	position: relative;
}

::-moz-selection {
	background: $link-color;
	color: $white;
}

::selection {
	background: $link-color;
	color: $white;
}

body {
	font-family: $bodyfont;
	background-color: $secondarycolor;
	-webkit-font-smoothing: antialiased;
	line-height: 20px;
	font-weight: 400;
	font-size: 16px;
	min-height: 100%;
	z-index: 20;
	background-image: url('../img/background.jpg');
	background-blend-mode: multiply;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center left;
	color: $text-color;
	transition: opacity 250ms ease-in-out;
	overflow-x: hidden;
	overflow-y: auto;

	@media only screen and (max-width: 1024px) {
		font-size: 14px;
		overflow-x: hidden;
	}

	&.active {
		overflow: hidden;

		&:after {
			content: '';
			z-index: 2500;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0.6;
			background-color: $text-color;
		}
	}
}

.wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
	padding-left: 25px;
	padding-right: 25px;
	position: relative;
	transition: padding .2s ease-in-out;
}

img {
	display: inline-block;
	height: auto;
	max-width: 100%;
	@include border-radius(2px);

	&.alignleft {
		float: left;
	}

	&.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&.alignright {
		float: right;
	}
}

figure {
	&.thumb {
		float: left;
		margin-right: 20px;
	}
}

.search-highlight {
	background-color: $link-color;
	color: $white;
}

.clearfix {
	clear: both;
}


table {
	table-layout: auto;
	width: 100%;
	border: none;

	th, td {
		border: none;
		height: 25px;
		vertical-align: middle;
	}

	th {
		font-weight: 700;
	}
}

input {
	background-color: transparent;
	cursor: pointer;
	color: $red;
	border: none;
	width: 50px;
	padding: 0 6px 0 0;
	transition: all ease-in-out 250ms;
	font-weight: 700;

	&[readonly] {
		font-weight: 400;
		pointer-events: none;
		cursor: auto;
		opacity: 1;
		color: $text-color;

		&.blue {
			color: $blue !important;
		}

		&.green {
			color: $green !important;
		}
	}

	&:focus {
		color: $blue-dark;
		background-color: $white;
		outline: none;
		padding: 0 0 0 6px;
		border: none;
	}

	&.totals {
		font-weight: 700 !important;
	}

	&.blue {
		color: $blue !important;
	}

	&.green {
		color: $green !important;
	}
}

.main-content {

	.contents {
		display: none;
		height: calc(100vh - 92px);

		&.active {
			display: block;
		}
	}
}

.output {
	top: 64px;
	position: absolute;
	width: 65%;
	left: -100%;
	transition: left ease-in-out 300ms;

	@media only screen and (max-width: 1180px) {
		width: 85%;
	}

	&.show {
		left: 0;
	}

	&__inner {
		padding: 0 20px 20px 90px;
		background-color: $blue-bg;

		.tab-2050 & {
			background-color: $green-bg;
		}

		@media only screen and (max-width: 1180px) {
			padding: 0 20px 20px 75px;
		}
	}

	h2 {
		position: relative;
		top: -55px;

		span {
			color: $blue-light;
			font-size: 14px;

			.tab-2050 & {
				color: $green-light;
			}
		}
	}

	&__intro {
		padding: 80px 0 40px;
		max-width: 80%;

		@media only screen and (max-width: 1180px) {
			padding: 50px 0 20px;
		}

		h3 {
			margin-bottom: 10px;
			font-size: 24px;
		}
	}

	&__outro {
		padding: 40px 0;
		max-width: 80%;

		h4 {
			text-transform: uppercase;
			font-size: 20px;
		}

		p {
			margin-bottom: 0;
		}
	}
}