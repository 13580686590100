.parameters {
	position: absolute;
	z-index: 999;
	width: 475px;
	max-width: 555px;
	right: -625px;
	top: 66px;
	padding: 0 60px 20px 20px;
	background: $blueish;
	transition: right ease-in-out 300ms;
	box-shadow: 0 0 10px fade-out($text-color, 0.5);

	@media only screen and (max-width: 1024px) {
		width: 45%;
		padding: 0 20px 20px;
	}

	&.active {
		right: 0;
	}

	h2 {
		position: relative;
		display: block;
		text-align: right;
		top: -55px;
		@media only screen and (max-width: 1024px) {
			left: 80px;
		}

		span {
			font-size: 14px;

			&.blue {
				color: $blue-light;
			}

			&.green {
				color: $green-light;
			}
		}
	}

	&__inputs {
		.line {
			display: flex;
			align-items: flex-end;
			padding: 4px 0;

			p {
				flex: 0 1 210px;
				margin: 0 15px 0 5px;
			}

			.input-cell {
				input {
					width: 10ch;
					display: inline-block;
					text-align: right;
				}
			}
		}

		h3 {
			margin: 0 0 10px 0;
			width: 100%;
			display: flex;
			align-items: center;
			font-size: 18px;
			text-transform: uppercase;
			line-height: 1;
			padding-left: 0;
			position: relative;

			&:before {
				content: '';
				width: 30px;
				height: 30px;
				background-color: $black;
				display: inline-block;
				margin-right: 10px;
				margin-top: -5px;
				color: $white;
			}

			span {
				content: '1';
				background: $white;
				color: $black;
				width: 22px;
				height: 22px;
				font-size: 14px;
				position: absolute;
				display: inline-block;
				@include border-radius(200%);
				top: -1px;
				text-align: center;
				line-height: 22px;
				left: 4px;
				z-index: 10;
			}
		}
	}


	.mutatie,
	.electric,
	.windturbine,
	.zonnevelden,
	.oppervlakte {
		position: relative;
		margin-bottom: 15px;
		padding-bottom: 10px;
		padding-top: 5px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 215px;
			z-index: -1;
			background-color: $white;
			opacity: 0.5;
			bottom: 0;
		}
	}

	.omrekenfactor {
		padding-top: 10px;
		background-color: fade-out($white, .5);
		padding-bottom: 10px;

		h3 {
			&:before {
				background-color: transparent;
			}
		}
	}
}