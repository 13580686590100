.output1 {
	&__variables {
		table {

			thead {
				th {
					font-weight: 700;
				}
			}

			tbody {
				td {
					&.name {
						width: 28%;
					}

					&.cell {
						text-align: right;
						width: 8ch;
						padding-right: 5px;
					}
				}

				tr {
					&.totals {
						font-weight: 700 !important;
					}
				}
			}
		}
	}
}