header, section, footer, aside, nav, article, figure {
	display: block;
	margin: 0px;
	padding: 0px;
}

html {
	color: #000;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset, img {
	border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var, optgroup {
	font-style: inherit;
	font-weight: inherit;
}

del, ins {
	text-decoration: none;
}

li {
	list-style: none;
}

caption, th {
	text-align: left;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}

q:before, q:after {
	content: '';
}

abbr, acronym {
	border: 0;
	font-variant: normal;
}

sup {
	vertical-align: baseline;
}

sub {
	vertical-align: baseline;
}

legend {
	color: #000;
}

input, button, textarea, select, optgroup, option {
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
}

input, button, textarea, select {
	*font-size: 100%;
}