.base-nav {
	position: absolute;
	top: 82px;
	z-index: 2000;
	transform: translateX(-50%);
	left: 50%;
	width: 600px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	opacity: 0;
	transition: opacity ease-in-out 200ms;
	transition-delay: 125ms;

	@media only screen and (max-width: 1180px) {
		width: 480px;
	}

	.menu-item {
		height: 54px;
		width: 200px;
		overflow: hidden;
		border-radius: 10px;
		border: 6px solid $white;
		background-color: $blue;
		color: $white;
		transform-origin: center;
		font-size: 20px;
		font-weight: 700;
		cursor: pointer;
		transition: all ease 125ms;
		box-shadow: 0 0 5px fade-out($text-color, 0.5);


		@media only screen and (max-width: 1180px) {
			font-size: 18px;
			width: 140px;
		}


		&.active {
			background-color: $blue-dark;
			pointer-events: none;

			&.content-2050 {
				background-color: $green-dark;
			}
		}

		&:hover {
			background-color: $blue-light;
			scale: 102%;
			transform-origin: center;
			box-shadow: 0 0 10px fade-out($text-color, 0.5)
		}

		&.content-2050 {
			background-color: $green;

			&:hover {
				background-color: $green-light;
			}
		}
	}

	&.active {
		opacity: 1;
	}
}

.output-nav {
	position: fixed;
	top: 50%;
	z-index: 2000;
	transform: translateY(-50%);

	li {
		display: block;
		position: relative;
		margin: 10px 0;
		padding: 35px 15px 35px 10px;
		@include border-radius(0 20px 20px 0);
		background-color: $blue;
		font-size: 30px;
		color: $white;
		z-index: 333;
		border: 5px solid $white;
		border-left: none;
		cursor: pointer;
		box-shadow: 0 0 5px fade-out($text-color, 0.5);
		transition: ease all 125ms;

		&:first-child {
			margin-top: 0;
		}

		&.output-2050 {
			background-color: $green;
		}


		&.show {
			box-shadow: 0 0 10px fade-out($text-color, 0.5);
			background-color: $blue-dark;
			z-index: 666;

			&.output-2050 {
				background-color: $green-dark;
			}
		}

		&:hover {
			box-shadow: 0 0 15px fade-out($text-color, 0.5);
		}
	}
}

.parameters-toggle {
	position: fixed;
	z-index: 2000;
	top: 20%;
	right: 0;
	padding: 30px 10px 30px 15px;
	@include border-radius(20px 0 0 20px);
	background-color: $red;
	font-size: 30px;
	color: $white;
	border: 5px solid $white;
	border-right: none;
	cursor: pointer;
	box-shadow: 0 0 5px fade-out($text-color, 0.5);
	transform-origin: center right;
	transition: ease all 125ms;

	&.active {
		background-color: darken($red, 10%);
		box-shadow: 0 0 10px fade-out($text-color, 0.5);
	}

	&:hover {
		scale: 102%;
		box-shadow: 0 0 15px fade-out($text-color, 0.5);
	}
}

#uitleg-toggle {
	position: fixed;
	z-index: 2000;
	bottom: 20%;
	right: 0;
	padding: 30px 10px 30px 15px;
	@include border-radius(20px 0 0 20px);
	background-color: $blue-dark;
	font-size: 30px;
	color: $white;
	border: 5px solid $white;
	border-right: none;
	cursor: pointer;
	transition: ease all 125ms;
	box-shadow: 0 0 5px fade-out($text-color, 0.5);
	transform-origin: center right;

	&.active {
		box-shadow: 0 0 10px fade-out($text-color, 0.5);
		z-index: 3500;
	}

	&:hover {
		scale: 102%;
		box-shadow: 0 0 15px fade-out($text-color, 0.5);
	}
}

