.output2 {
	left: -100%;
	transition: left ease-in-out 300ms;

	.co2 {
		padding-top: 20px;
		margin-top: 30px;
		background-color: $yellow-bg;

		&__title {
			margin: 5px 0 15px;
			font-size: 20px;
			font-weight: 700;

			span {
				font-weight: 400;
				text-transform: none;
			}
		}

		table {
			tbody {
				td {
					&.cell {
						text-align: right;
						padding-right: 5px;
					}
				}
			}

			thead {
				th {
					text-align: right;
				}
			}

			tr {

				&.underline {
					border-bottom: 1px solid $text-color !important;
				}

				&.bg-dark {
					background-color: $yellow-dark;

					&.totals {
						height: 50px;
					}

					&:after {
						height: 50px;
						z-index: 0;
						content: '';
						position: absolute;
						background-color: $yellow-dark;
						left: 0;
						right: 0;
					}

					td {
						position: relative;
						z-index: 10;


					}
				}

				&.extra-bg {
					background-color: $yellow;

					&:after {
						height: 25px;
						z-index: 0;
						content: '';
						position: absolute;
						background-color: $yellow;
						left: 0;
						right: 0;
					}

					td {
						position: relative;
						z-index: 10;
					}
				}
			}

		}
	}

	&__variables {
		table {

			thead {
				th {
					width: auto;
					font-weight: 600;
					text-align: left;
				}
			}

			tbody {
				td {
					&.name {
						width: 33%;
					}

					&.cell {
						width: auto;
					}

					&.right {
						text-align: right;
						padding-right: 5px;
					}
				}

				tr {
					&.totals {
						font-weight: 700 !important;
					}
				}
			}
		}
	}
}