#uitleg,
#home {
	width: 700px;
	max-width: 90%;
	max-height: 600px;
	position: absolute;
	padding: 45px 40px;
	left: 50%;
	top: 50%;
	opacity: 0;
	z-index: 3000;
	transform: translate(-50%, -50%);
	text-align: left;
	border: 5px solid $primarycolor;
	background-color: $white;
	@include border-radius(20px);
	transition: opacity 50ms ease-in-out;
	visibility: hidden;
	box-shadow: 0 0 8px fade-out($text-color, 0.5);
	pointer-events: none;

	.overflow {
		display: block;
		max-height: 570px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	p {
		line-height: 30px;
		font-size: 16px;
	}

	ul {
		list-style-type: square;
		padding-left: 20px;

		li {
			list-style: square;
		}
	}

	ol {
		list-style-type: decimal;
		padding-left: 20px;
		li {
			list-style: decimal;
		}
	}


	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	h3 {
		margin-bottom: 10px;
	}

	.close {
		top: -15px;
		right: -15px;
		position: absolute;
		background-color: $red;
		color: $white;
		font-size: 40px;
		font-weight: 700;
		width: 40px;
		line-height: 40px;
		text-align: center;
		height: 40px;
		border: 5px solid $white;
		@include border-radius(200px);
		transition: all ease-in-out 125ms;
		box-shadow: 0 0 10px fade-out($text-color, 0.5);
		cursor: pointer;

		&:hover {
			background-color: darken($red, 10%);
			box-shadow: 0 0 15px fade-out($text-color, 0.5);
			scale: 102%;
		}
	}
}

#home {
	top: 525px;
	text-align: center;

	h1 {
		font-size: 28px;
		margin-bottom: 10px;
		color: $greenish;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1.5;

		span {
			color: $red;
			font-size: 32px;
			line-height: 1;
			padding: 10px 5px 0 5px;
		}
	}
}