
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color;
	font-family: $titlefont;
	font-weight: 700;
	display: inline-block;
}

h1 {
	font-size: 28px;
	margin: 0;
}

h2 {
	font-size: 20px;
	margin: 0;
}

h3 {
	font-size: 28px;
	margin: 0;
}

h4 {
	margin: 0 0 10px 0;
	text-transform: uppercase;
}

h5 {
	font-size: 16px;
	font-weight: bold;
	margin: 0 0 8px 0;
}

h6 {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 24px 0;
}

p {
	margin: 0 0 8px 0;
	font-size: 16px;
	font-weight: 300;
	color: $text-color;

	&.blue {
		color: $blue;
	}
}

strong {
	font-weight: bold;
}

.blue {
	color: $blue;
	font-weight: 700;
}


.green {
	color: $green;
	font-weight: 700;
}


.strong {
	font-weight: 700;
}

a {
	color: $link-color;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: darken($link-color, 5%);
		text-decoration: underline;
	}

}

blockquote {
	border-left: $primarycolor 5px solid;
	padding: 10px 0px 10px 20px;
	margin: 20px 15px 20px 30px;

	p {
		color: lighten($black, 40%);
		margin: 0;
	}
}


h4.notice {
	font-weight: 900;
	color: $red;
	margin: 0 0 20px;
	font-size: 16px;
	text-align: left;
	border: 1px solid $red;
	padding: 12px 15px;
	line-height: 1.5;
	vertical-align: center;
	background-color: lighten($red, 33%);
	border-radius: 8px;

	.home & {
		margin-bottom: 0;
	}

	&:before {
		content: '!';
		background-color: $red;
		color: $white;
		height: 18px;
		width: 18px;
		font-size: 12px;
		border-radius: 200px;
		margin-right: 8px;
		line-height: 1;
		display: inline-grid;
		place-items: center;
	}

	.uitleg-toggle {
		text-decoration: underline;
		cursor: help;
		color: darken($red, 15%);

		&:hover {
			text-decoration: none;
			color:darken($red, 25%);
		}
	}
}

.main-content {
	position: relative;
	overflow-x: hidden;
}